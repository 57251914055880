<template>
  <div class="app-container">
    <eHeader  :query="query" :merOptions="queryMerOptions" :productOptions="queryProductOptions" :channelOptions="queryChannelOptions"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="merName" label="商户名称">
        <template slot-scope="scope">
          <span>{{ parseMer(scope.row.merCode) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productName" label="产品名称"/>
      <el-table-column prop="productCode" label="产品编码"/>
      <el-table-column prop="channelName" label="资金渠道名称">
        <template slot-scope="scope">
          <span>{{ parseChannel(scope.row.channelCode) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="channelCode" label="资金渠道编码"/>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','PRODUCTCHANNEL_ALL','PRODUCTCHANNEL_EDIT'])" :data="scope.row" :sup_this="sup_this" :productOptions="queryProductOptions" :channelOptions="queryChannelOptions"/>
          <el-popover
            v-if="checkPermission(['ADMIN','PRODUCTCHANNEL_ALL','PRODUCTCHANNEL_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定删除此产品渠道关联吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="delRelation(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initPmsData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/pms/prodwithchannel/header'
import edit from '@/components/pms/prodwithchannel/edit'
import { queryAllMerchantInfos,queryAllChannelInfos,queryAllProductInfos,deleteRelation} from '@/api/dataPms'
export default {
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,formData:{},
      queryMerOptions: [],queryProductOptions: [],queryChannelOptions: []
    }
  },
  created() {
    this.queryMerOption()
    this.queryChannelOption()
    this.queryProductOption()
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/api/configure/channel/queryChannelRelationInfoByParams'
      const query = this.query
      const channelCode = query.channelCode
      const productCode = query.productCode
      const merCode=query.merCode
      this.formData = { P: this.page, pageSize: this.size }
      if (channelCode !== '' && channelCode !== null) { this.formData['channelCode'] = channelCode }
      if (productCode !== '' && productCode !== null) { this.formData['productCode'] = productCode }
      if (merCode !== '' && merCode !== null) { this.formData['merCode'] = merCode }
      this.params = {'content':JSON.stringify(this.formData)}
      return true
    },
    queryMerOption(){
     const params = {'content':JSON.stringify()}
     queryAllMerchantInfos(params).then(res => {
        this.queryMerOptions = res.value
      })
    },
    queryChannelOption(){
     const params = {'content':JSON.stringify()}
     queryAllChannelInfos(params).then(res => {
        this.queryChannelOptions = res.value
      })
    },
    queryProductOption(){
     const params = {'content':JSON.stringify()}
     queryAllProductInfos(params).then(res => {
        this.queryProductOptions = res.value
      })
    },
    parseMer(params){
      for (var i = this.queryMerOptions.length - 1; i >= 0; i--) {
        if(this.queryMerOptions[i].merCode==params){
          return this.queryMerOptions[i].merName
        }
      }
    },
    parseChannel(params){
      for (var i = this.queryChannelOptions.length - 1; i >= 0; i--) {
        if(this.queryChannelOptions[i].channelCode==params){
          return this.queryChannelOptions[i].channelName
        }
      }
    },
    delRelation(info) {
      this.delLoading = true
      this.formData = { id: info }
      this.params = {'content':JSON.stringify(this.formData)}
      deleteRelation(this.params).then(res => {
        this.delLoading = false
        this.$refs[info].doClose()
        this.init()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
